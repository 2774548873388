/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../pwasaas/src/style/abstract/variables';
@import '../../../../../pwasaas/src/style/abstract/media';
@import '../../../../../pwasaas/src/style/abstract/button';
@import '../../../../../pwasaas/src/style/abstract/loader';
@import '../../../../../pwasaas/src/style/abstract/icons';
@import '../../../../../pwasaas/src/style/abstract/image';
@import '../../../../../pwasaas/src/style/abstract/parts';

:root {
    --header-menu-main-item-margin-right-after-tablet: 2%;

    @include mobile {
        --saas-header-nav-links-position: static;
    }
}

.SaasHeader {
    --saas-header-menu-bottom-background: #000000;

    &-LogoWrapper {
        @include mobile {
            max-width: 15rem;
        }
    }

    &-NavLogo {
        @include mobile {
            margin: 0 0 0 40px;
        }
    }
}